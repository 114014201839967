<template>

    <div>
        <div class=" ml-1 d-flex flex-wrap align-items-center">
            <b-form-radio
                    class="mr-1 mb-1"
                    v-model="filterData.graphType"
                    variant="success"
                    value="draw_all"
                    @change="drawGraph('draw_all')"
            >
                <span style="white-space: nowrap;">{{$t('label_all')}}</span>
            </b-form-radio>
            <b-form-radio v-for="item in taskTypes"
                          class="mr-1 mb-1"
                          v-model="filterData.graphType"
                          :value="item.id"
                          @change="drawGraph('task',item.id)"
            >
                <span style="white-space: nowrap;">{{item.translation_index ? $t(item.translation_index) : item.name}}</span>
            </b-form-radio>
            <b-form-radio
                    class="mr-1 mb-1"
                    v-model="filterData.graphType"
                    variant="success"
                    value="workingtime"
                    @change="drawGraph('workingtime', 1)"
            >
                <span style="white-space: nowrap;">{{$t('label_dashboard_working_time')}}</span>
            </b-form-radio>
            <b-form-radio
                    class="mr-1 mb-1"
                    v-model="filterData.graphType"
                    variant="success"
                    value="contact_meetings"
                    @change="drawGraph('contact_meetings', 1)"
            >
                <span style="white-space: nowrap;">{{$t('label_meeting_with_contact')}}</span>
            </b-form-radio>
        </div>
        <div>
            <div class="mt-1 ml-1" v-if="series[0].data.length < 1">{{$t('label_no_items')}}</div>
            <apexcharts ref="timesheet" width="100%" :height="graphHeight" type="rangeBar" :options="chartOptions"
                        :series="series"></apexcharts>

        </div>
    </div>

</template>

<script>

    import {
        BFormRadio
    } from 'bootstrap-vue'

    import VueApexCharts from 'vue-apexcharts'
    let selfInstance = false;
    export default {

        components: {
            BFormRadio,
            apexcharts: VueApexCharts,
        },
        props: ['taskTypes'],
        data() {
            return {

                graphHeight: 100,

                series: [{
                    name: '', //name collection of data // can be several
                    data: [],
                }],

                chartOptions: {
                    chart: {
                        events: {
                            dataPointSelection: function(event, chartContext, config) {
                               // console.log(event,chartContext);
                               let seriesIndex = config.seriesIndex;
                               let dataIndex = config.dataPointIndex;

                                let data = selfInstance.series[seriesIndex].data[dataIndex];
                                   // console.log(data);
                                   if(data && data.info.redirectRoute){
                                       selfInstance.$router.push(data.info.redirectRoute);
                                   }

                            }
                        },
                        offsetY: 0,
                        parentHeightOffset: 0,
                        // height: 350,
                        // type: 'rangeBar'
                        toolbar: { // actions
                            show: false,
                            // tools: {
                            //     download: true,
                            //     selection: true,
                            //     zoom: true,
                            //     zoomin: true,
                            //     zoomout: true,
                            //     pan: true,
                            //     // reset: true | '<img src="/static/icons/reset.png" width="20">',
                            //     customIcons: []
                            // },
                        },
                        // defaultLocale: 'en'
                        // locales: [{
                        //     name: 'en',
                        //     options: {
                        //         months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                        //         shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        //         days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                        //         shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        //         toolbar: {
                        //             download: 'Download SVG',
                        //             selection: 'Selection',
                        //             selectionZoom: 'Selection Zoom',
                        //             zoomIn: 'Zoom In',
                        //             zoomOut: 'Zoom Out',
                        //             pan: 'Panning',
                        //             reset: 'Reset Zoom',
                        //         }
                        //     }
                        // }]
                        // zoom: {
                        //     enabled: true,
                        //     type: 'xy',
                        //     autoScaleYaxis: false,
                        //     zoomedArea: {
                        //         fill: {
                        //             color: '#90CAF9',
                        //             opacity: 0.4
                        //         },
                        //         stroke: {
                        //             color: '#0D47A1',
                        //             opacity: 0.4,
                        //             width: 1
                        //         }
                        //     }
                        // }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true,
                            // dataLabels: {
                            //     hideOverflowingLabels: false
                            // },
                            // borderRadius: 10,
                            barHeight: '50%',
                            // colors:{
                            //     backgroundBarRadius: 10,
                            //     backgroundBarColors: ['#ff9f43']
                            // }
                        }
                    },
                    dataLabels: { //!! text inside bar
                        enabled: true,
                        formatter: function (val, opts) {
                            var label = opts.w.globals.labels[opts.dataPointIndex]
                            // var a = this.$moment(val[0])
                            // var b = this.$moment(val[1])
                            // var diff = b.diff(a, 'days')
                            // return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
                            return label;
                        },
                        // offsetY: '2',
                        style: {
                            colors: ['#f3f4f5', '#fff'],
                            fontSize: '10px',
                        },
                        // background:{
                        //     enabled: true,
                        //     borderRadius: '10'
                        // }
                    },
                    xaxis: {
                        type: 'datetime',
                        min: this.$moment().hours(0).minutes(0).seconds(0).unix() * 1000,
                        max: this.$moment().hours(23).minutes(59).seconds(59).unix() * 1000,
                        labels: {
                            //     formatter: function(value, timestamp, opts) {
                            //         return formatDate(value, 'HH:mm', 'unix')
                            //     }
                            datetimeUTC: false
                        },
                        axisBorder: {
                            show: true,
                            color: '#78909C',
                            height: 1,
                            width: '100%',
                            offsetX: 0,
                            offsetY: 0
                        },
                    },
                    yaxis: {
                        show: false
                    },
                    grid: {
                        show: true,
                        // borderColor: '#90A4AE',
                        borderColor: '#e7e7e7',
                        row: {
                            // colors: ['#f3f4f5', '#fff'], //  dif colors for rows
                            opacity: 1
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false
                            }
                        },
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                    },

                    tooltip: {
                        fillSeriesColor: true,
                        x: {
                            formatter: function (v) {
                                return formatDate(v / 1000, 'HH:mm', 'unix');
                            }
                        },
                        y: {
                            formatter: function (v,config) {
                                let seriesIndex = config.seriesIndex;
                                let dataIndex = config.dataPointIndex;

                                let data = selfInstance.series[seriesIndex].data[dataIndex];
                                return data? data.info.label : '';
                            }
                        }
                    }

                },

                filterData: {
                    graphType: 'draw_all',
                },
                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [10, 25, 50, 100],
                },

            }

        },

        watch: {

            filterData: {
                handler: function (old, newVal) {
                    // this.refreshDataTable();
                },
                deep: true
            },

        },

        created() {
            this.drawGraph('draw_all');
            selfInstance = this;
        },


        methods: {

            drawGraph(type, type_id) {
                this.isBusy = true;

                let url = '';
                switch (type) {
                    case 'agreement_tasks':
                    case 'task':
                        url = "/agreement_tasks";
                        break;
                    case 'workingtime':
                        url = "/workingtime";
                        break;
                    case 'contact_meetings':
                        url = "/contact_meetings";
                        break;
                    case 'draw_all':
                        url = "/dashboard/timesheet";
                        break;
                }

                let params = {
                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc ? true : null,
                    is_mine: 1,
                    module_name: 'dashboard'
                };

                if ((type == 'task' || type == 'agreement_tasks') && type_id) {
                    params.task_type_id = type_id;
                    params.extra_search = 'this_day';
                }

                this.async('get', url, {
                    params: params
                }, function (resp) {

                    this.prepareData(resp.data.items, type);

                    this.isBusy = false;
                });
            },

            prepareData(items, type) {
// console.log('items', items);
                let prepData = [];
                let itemsCount = 0;

                for (let i = 0; i < items.length; i++) {

                    let item = items[i];

                    let dateRange = [];

                    let start = '';
                    let end = '';

                    let color = '#7C70F9';

                    if (this.$can('view', 'workingtime.tab_details') && type == 'workingtime' || item.timesheet == 'workingtime') {

                        let title = item.dashboard_timesheet_title + '_' + i;

                        if (item.trackers && item.trackers.length > 0) {
                            itemsCount++;
                            for (let j = 0; j < item.trackers.length; j++) {
                                let tracker = item.trackers[j];
                                dateRange = [];
                                start = tracker.start_at;
                                end = tracker.end_at;
                                if (!end) {
                                    end = this.$moment.unix() * 1000;
                                }
                                dateRange.push(start * 1000);
                                dateRange.push(end * 1000);

                                let redirectRoute = item.deal_id > 0? {name:'deal_tabs', params:{id: item.deal_id, alias: 'task'}} : {name:'agreement_tabs', params:{id: item.agreement_id, alias: 'task'}};
                                let dataLabel = (item.agreement ? item.agreement.deal_number + ' (' + item.agreement.first_name+' '+item.agreement.last_name +'): ' : '');
                                prepData.push({x: title, y: dateRange, fillColor: color, info:{redirectRoute: redirectRoute, label: dataLabel}});
                            }
                        }

                    } else if(this.$can('view', 'agreement.tab_tasks') && type == 'task'  || item.timesheet == 'agreement_tasks' ){
                       if (item.start) {
                            start = item.start;
                            end = item.end;
                            if (item.task_type && item.task_type.color) {
                                color = item.task_type.color;
                            }
                            // dateRange.push(start.unix() * 1000);
                            // dateRange.push(end.unix() * 1000);

                            dateRange.push(start * 1000);
                            dateRange.push(end * 1000);

                            let title = item.dashboard_timesheet_title + ' ' + (i + 1);

                            itemsCount++;
                            let redirectRoute = {};
                           let dataLabel = '';
                           if(item.agreement) {
                                // redirectRoute = (item.agreement.is_deal? {name:'deal_tabs', params:{id: item.agreement.id, alias: 'task'}} : {name:'agreement_tabs', params:{id: item.agreement.id, alias: 'task'}});
                               redirectRoute = {name: 'agreement_task_details',  params:{id: item.id}};
                               dataLabel = item.agreement.deal_number + ' (' + item.agreement.first_name+' '+item.agreement.last_name +')';
                            } else if (item.contract_registry){
                               redirectRoute = {name: 'agreement_task_details',  params:{id: item.id}};
                               dataLabel =  item.contract_registry.contract_subject;
                           } else {
                               redirectRoute = {name: 'agreement_task_details',  params:{id: item.id}};
                               dataLabel =  item.task_type_name;
                           }

                            prepData.push({x: title, y: dateRange, fillColor: color, info:{redirectRoute: redirectRoute, label: dataLabel}});
                        }

                    } else if (this.$can('view', 'contact.tab_details') && type == 'contact_meetings' || item.timesheet == 'contact_meetings' ) {
                        let title = item.meeting_type + '(' + item.id + ')';

                        start = item.start_at;
                        end = item.deadline_at;
                        if((parseInt(end) - parseInt(start)) < 60*60) {
                            end = parseInt(start) + 60*60;
                        }
                        // console.log(start, end);
                        if(start && end) {

                            dateRange.push(start * 1000);
                            dateRange.push(end * 1000);

                            itemsCount++;
                            let redirectRoute =  {name:'contact_tabs', params:{id: item.contact_id, alias: 'meeting'}};
                            let dataLabel = item.first_name + ' ' + item.last_name;
                            prepData.push({x: title, y: dateRange, fillColor: color, info:{redirectRoute: redirectRoute, label: dataLabel}});
                        }

                    }

                }

                if (itemsCount > 1) {
                    if(itemsCount == 2){
                        this.graphHeight = 128;
                    } else {
                        this.graphHeight = itemsCount * 54;
                    }

                } else {
                    this.graphHeight = 108;
                }

                // console.log('prepData', prepData);

                this.series = [{
                    data: prepData
                }];

            }
        },

    }
</script>

