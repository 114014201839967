<template>
  <div class="table-container-wrap">
    <b-table
      :ref="PREFIX+'_TABLE'"
      class="position-relative transited-table"
      :items="tableItems"
      responsive
      no-border-collapse
      :busy="isBusy"
      :fields="columnsDefs"
      primary-key="id"
      :sort-by.sync="tableData.sortBy"
      no-local-sorting
      :sort-desc.sync="tableData.sortDesc"
      show-empty
      :tbody-transition-props="{ name: 'flip-list'}"
      :empty-text="$t('label_no_table_data')"
      hover
      @row-clicked="processRowClick"
    >
      <template #cell(consultant)="data">
        <router-link
          v-if="data.item.consultant && $can('view', 'consultant.tab_details')"
          :to="{ name: 'consultant-details', params: { id: data.item.consultant.id } }"
        >
          {{ data.item.consultant? data.item.consultant.name : '--' }}
        </router-link>
        <span v-else>
          <feather-icon icon="MinusIcon" />
        </span>
      </template>

      <template #head()="data">
        <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
      </template>

      <template #cell(firm_name)="data">
        <router-link
          v-if="$can('view', 'contact.tab_details') && data.item.contact_id"
          target="_blank"
          :to="{ name: 'contact-details', params: { id: data.item.contact_id } }"
        >
          <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </router-link>
        <template v-else>
          <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </template>
      </template>
      <template #cell(status)="data">
        <b-badge
          v-if="data.item.status_id && agreement_statuses.findWhere('id', data.item.status_id)"
          pill
          :variant="`light-${resolveAgreementStatusVariant(agreement_statuses.findWhere('id', data.item.status_id)?agreement_statuses.findWhere('id', data.item.status_id).name_alias : '', true)}`"
          class="text-capitalize"
        >
          {{ $t(`${agreement_statuses.findWhere('id', data.item.status_id).translation_index}`) || '--' }}
        </b-badge>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>
      <template #cell(note)="{ value, item }">
        <b-badge
          v-if="value && value.compelling_role"
          pill
          variant="primary"
          class="text-capitalize"
        >
          {{ $t(`label_${value.compelling_role}`) }}
        </b-badge>
        <feather-icon
          v-else
          icon="MinusIcon"
        />
      </template>
      <template #cell(deal_number)="data">

        <div style="min-width: 110px">
          <router-link
            v-if="$can('view', 'deal.tab_details')"
            target="_blank"
            :to="{ name: 'deal-details', params: { id: data.item.deal.id } }"
          >
            {{ data.item.deal_number }}
          </router-link>
          <template v-else>
            {{ data.item.deal_number }}
          </template>
        </div>
      </template>
    </b-table>

    <router-link :to="{ name: 'deals-without-action' }">
      <b-badge
        variant="primary"
        class="ml-1"
      >
        {{ $t('label_show_all') }}
      </b-badge>
    </router-link>
  </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import {resolveAgreementStatusVariant} from "@/views/pages/deal/moduleHelper";

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,

            vSelect,

        },
        // props:['tableType'],
        data() {
            return {
                // MODULE_PREFIX,
                PREFIX: 'without_activity',
                selectedColumns:[],

              columnsDefs: [
                {label: 'label_deal_number', key: 'deal_number', sortable: false},
                {label: 'label_firm_name', key: 'firm_name', sortable: false},
                {label: 'label_consultant_leading_case', key: 'consultant', sortable: false},
                {
label: 'label_last_date_of_activity',
key: 'activ_date',
sortable: false,
formatter: function (v) {
                    return formatDate(v, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm');
                  }
},
                {label: 'label_status', key: 'status', sortable: false},
                {label: 'label_compelling_role', key: 'note', sortable: false},
              ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [8, 10, 25, 50, 100],
                },

              agreement_statuses:[],

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {
          this.async('get', '/select_options', {params:{options:['agreement_statuses']}}, function(res){
            // this.taskTypes = res.data.agrm_task_types;
            this.agreement_statuses = res.data.agreement_statuses;
          });
            this.refreshDataTable();

        },


        methods: {
            resolveAgreementStatusVariant,
            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/deal/without_activity', {
                    params: {
                        // contact_id: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {
                  this.tableItems = resp.data.items?.map(agreementItem => ({
                    agreement_id: agreementItem.agreement_id,
                    activ_date: agreementItem.date,
                    ...agreementItem.agreement,
                    note: agreementItem.note,
                  }));
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){

                if(item.deal_id && this.$can('view', 'deal.tab_details')){
                    this.$router.push({name:'deal-details', params: {id:item.deal_id}});
                } else if(this.$can('view', 'agreement.tab_details')){
                    this.$router.push({name:'agreement-details', params: {id:item.agreement_id}});
                }

            }
        },

    }
</script>
