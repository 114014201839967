import store from '@/store'


export const MAIL_DYNAMIC_STORE_NAME = 'app-mail'
export const MAIL_PREFIX = 'mail'
export const PARTICIPANT_PREFIX = 'participant'
export const memberTypes = [{value: "sender", label: 'label_senders'}, {value: "recipient", label: 'label_recipients'}];
export const mailTypes = [{
    "alias": "undecided",
    "trans_index": "label_undecided_mail",
    "name": "Niezadekretowana poczta"
}, {
    "alias": "incoming",
    "trans_index": "label_incoming_mail",
    "name": "Poczta przychodz\u0105ca"
}, {
    "alias": "outcoming",
    "trans_index": "label_outcoming_mail",
    "name": "Poczta wychodz\u0105ca"
}];

