<template>

    <div class="table-container-wrap">
     <div class="tt-header" >
         <b-form-checkbox v-if="loggedUser.perm_undecided_mail == 1"
                 id="show-all-checker-1"
                 class="mr-1 show-all-checker mb-1"
                 value="1"
                 unchecked-value="0"
                 v-model="filterData.show_all"

         >
             <label for="show-all-checker-1">{{ $t('label_show_all') }}</label>
         </b-form-checkbox>
     </div>
        <b-table
                ref="table"
                class="position-relative transited-table"
                :busy="isBusy"
                no-border-collapse
                :items="tableItems"
                responsive
                :fields="columnsDefs"
                primary-key="id"
                :sort-by.sync="tableData.sortBy"
                no-local-sorting
                :sort-desc.sync="tableData.sortDesc"
                show-empty
                :tbody-transition-props="{ name: 'flip-list'}"
                :empty-text="$t('label_no_table_data')"
                @row-clicked="processRowClick"
                hover
        >

            <template #head()="data">
                <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
            </template>

            <template #cell(description)="data">
                <div v-b-tooltip.hover.bottom.window="data.item.description" class="one-line-limit">{{data.item.description}}</div>
            </template>

            <template #cell(source)="data">
                {{data.item.document_source == 'default'? data.item.user.name : $t('label_scanning_device')}}
            </template>

            <template #cell(document)="data">
                <a v-b-tooltip.hover.top="getFileName(data.item.documents[0])" v-if="data.item.documents[0]"  @click="previewFile($event,  'mail/download/', Object.assign(data.item.documents[0], {id:data.item.id}))" href="#"> <feather-icon icon="FileIcon"></feather-icon> </a>
            </template>

        </b-table>

    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, VBTooltip,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import {MAIL_PREFIX as PREFIX, PARTICIPANT_PREFIX} from './../../mail/moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,

            vSelect,

        },
        props:['loggedUser'],
        directives: {
            'b-tooltip': VBTooltip,
        },

        data() {
            return {
                PARTICIPANT_PREFIX,
                PREFIX,

                // checkedIds: [],

                selectedColumns:[],

                columnsDefs: [

                    {label: 'label_date_of_creating', key: 'created_at', sortable: false,
                        formatter: function (v, k, item) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix')
                        }
                    },
                    {label: 'label_description', key: 'description', sortable: false },
                    {label: 'label_document', key: 'document'},
                    {label: 'label_source', key: 'source', sortable: false},

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                },
                filterData: {
                    sort_by_source: 'all',
                    show_all: 0
                },
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.refreshDataTable();
        },

        methods: {
            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/'+this.PREFIX, {params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                        mail_type: 'undecided',

                        sort_by_source: this.filterData.sort_by_source,
                        show_all: this.filterData.show_all,

                    }}, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){
                if(this.$can('view', 'mail.tab_details'))
                this.$router.push({name:'mail_edit', params: {id:item.id}});
            },
            getFileName(file){
                return file.display_name + '.' + file.file_name.split('.').pop();;
            }
        },

    }
</script>