<template>
    <div >
        <div class="mb-1">
            <b-form-radio v-for="item in taskTypes"
                          class="mr-1 mb-1"
                          v-model="filterData.task_type"
                          button
                          size="sm"
                          button-variant="outline-primary"
                          :value="item.alias"
            >
                <span style="white-space: nowrap;">{{$t(item.label)}}</span>
            </b-form-radio>

            <div class="d-flex">

                    <b-form-checkbox v-if="loggedUser.perm_tasks == 1"
                            id="show-all-checker-8"
                            class="mr-1 show-all-checker"
                            value="1"
                            unchecked-value="0"
                            v-model="filterData.show_all"

                    >
                        <label for="show-all-checker-8">{{ $t('label_show_all') }}</label>
                    </b-form-checkbox>


            </div>

        </div>
        <div class="mb-1 d-flex flex-wrap">
            <b-form-radio v-for="item in taskSubTypes"
                          class="mr-1 mb-1"
                          v-model="filterData.task_type_id"
                          variant="success"
                          :value="item.id"
            >
                <span style="white-space: nowrap;">{{item.translation_index ? $t(item.translation_index) : item.name}}</span>
            </b-form-radio>
        </div>

        <div class="table-container-wrap">
            <b-table
                    :ref="PREFIX+'_TABLE'"
                    class="position-relative transited-table"
                    :items="tableItems"
                    responsive
                    no-border-collapse
                    :busy="isBusy"
                    :fields="columnsDefs"
                    primary-key="id"
                    :sort-by="tableData.sortBy"
                    no-local-sorting
                    :sort-desc.sync="tableData.sortDesc"
                    show-empty
                    :tbody-transition-props="{ name: 'flip-list'}"
                    :empty-text="$t('label_no_table_data')"
                    hover
                    @row-clicked="processRowClick"
            >


                <template #head()="data">
                    <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                </template>


                <template #cell(task)="data">

                    <div style="min-width: 350px">
                        <partial-text :key="data.item.id" :is-html="true" :text="data.item.task"></partial-text>
                    </div>

                </template>

                <template #cell(deadline_at)="data">

                    {{formatDate(data.item.deadline_at, 'HH:mm DD.MM.YYYY', 'unix')}}
                    <template v-if="data.item.is_cyclic == 1">
                        <br>
                        <span class="badge badge-light-secondary"><feather-icon icon="ClockIcon"/>
                                {{$t(resolveDeadlineText(data.item.name_alias))}}</span>
                    </template>


                </template>

                <template #cell(related_users)="data">
                            <span v-for="user,ind in data.item.executors"><span class="text-primary"
                                                                                    >{{user.name}}</span> </span>
                </template>
                <template #cell(owner_name)="data">
                            <span class="text-primary" >{{data.item.user? data.item.user.name : '--'}}</span>
                </template>
                <template #cell(task_type_name)="data">

                    <span class="badge badge-light-primary">{{data.item.task_type_name}}</span>

                </template>

                <template #cell(agreement_deal_number)="data">
                    <template v-if="data.item.agreement">
                        <router-link target="_blank" v-if="data.item.agreement.is_deal && data.item.agreement.deal && $can('view', 'deal.tab_details')"
                                     :to="{name: 'deal-details', params:{id:data.item.agreement.deal.id}}">
                            {{data.item.agreement.deal_number}}
                        </router-link>
                        <router-link target="_blank" v-else-if="data.item.agreement &&  $can('view', 'agreement.tab_details')"
                                     :to="{name: 'agreement-details', params:{id:data.item.agreement.id}}">
                            {{data.item.agreement.deal_number}}
                        </router-link>
                        <template v-else>
                            {{data.item.agreement.deal_number}}
                        </template>
                    </template>
                    <span v-else>--</span>
                </template>

            </b-table>
        </div>

    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip, BFormRadio
    } from 'bootstrap-vue'
    import {resolveTaskRoute} from '../../pagesHelper'

    import vSelect from 'vue-select'

    import {

        AGREEMENT_PREFIX as MODULE_PREFIX,

        TASK_PREFIX as PREFIX,
        resolveDeadlineText,
        resolveUsersText,
        taskTypes
    } from './../../agreement/moduleHelper'
    import partialText from '@/views/components/partialText'
    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,
            BFormRadio,

            vSelect,
            partialText
        },

        props:['taskSubTypes', 'loggedUser'],

        data() {
            return {

                MODULE_PREFIX,
                PREFIX,
                taskTypes,
                resolveDeadlineText,
                resolveUsersText,
                resolveTaskRoute,

                columnsDefs: [ //variant: 'danger' whole column
                    {label: 'label_deal', key: 'agreement_deal_number'},
                    {label: 'label_task_type', key: 'task_type_name', sortable: false},
                    {label: 'label_task_content', key: 'task', sortable: false},
                    {label: 'label_deadline', key: 'deadline_at', sortable: true},
                    {label: 'label_finishing', key: 'cyclic_deadline_at', sortable: false, formatter: function (v, k, item) {
                            return v ? formatDate(v, 'HH:mm DD.MM.YYYY', 'unix') : '--';
                        }
                    },
                    {label: 'label_task_for', key: 'related_users', sortable: false},
                    {label: 'label_task_from', key: 'owner_name', sortable: false},
                    // {label: 'label_action', key: 'actions'}

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [8, 10, 25, 50, 100],
                },

                filterData: {
                    task_type: 'actual_tasks',
                    task_type_id: null,
                    task_executor_id: null,
                    only_contract_registry: null,
                    show_all: 0
                },

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.filterData.task_executor_id = this.loggedUser.id;
            // console.log('loggedUser', this.loggedUser);
            this.refreshDataTable();
        },


        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                let url = '/agreement_' + this.PREFIX + 's' ;

                let params = {

                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,

                    extra_search: this.filterData.task_type,
                    task_type_id: this.filterData.task_type_id,
                    show_all: this.filterData.show_all,

                };
                  if(this.authUser().role == 'admin'){
                      if(this.filterData.task_executor_id > 0){
                          params.is_mine = 1;
                      }
                  } else {
                      params.is_mine = 1;
                  }

                this.async('get', url, {
                    params: params
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){
                    let route = this.resolveTaskRoute(item);
                    if(route){
                        console.log(route);
                        this.$router.push(route);
                    }
            }
        },

    }
</script>
