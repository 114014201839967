<template>
    <div class="table-container-wrap">

        <div class="tt-header">
            <b-form-checkbox
                    id="show-all-checker-7" v-if="loggedUser.perm_daily_meetings == 1"
                    class="mr-1 show-all-checker mb-1"
                    value="1"
                    unchecked-value="0"
                    v-model="filterData.show_all"

            >
                <label for="show-all-checker-7">{{ $t('label_show_all') }}</label>
            </b-form-checkbox>
        </div>


        <b-table
                :ref="PREFIX+'_TABLE'"
                class="position-relative transited-table"
                :items="tableItems"
                responsive
                no-border-collapse
                :busy="isBusy"
                :fields="columnsDefs"
                primary-key="id"
                :sort-by.sync="tableData.sortBy"
                no-local-sorting
                :sort-desc.sync="tableData.sortDesc"
                show-empty
                :tbody-transition-props="{ name: 'flip-list'}"
                :empty-text="$t('label_no_table_data')"
                hover
                @row-clicked="processRowClick"
                :tbody-tr-class="resolveRowClassVariant"
        >


            <template #head()="data">
                <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
            </template>

            <template #cell(type_trans_index)="data">

                    {{ $t(data.item.type_trans_index?data.item.type_trans_index: data.item.type_name) }}

            </template>

            <template #cell(deadline_at)="data">
                <div style="min-width: 116px; line-height: 14px">
                    <div>{{(data.item.start_at ? formatDate(data.item.start_at, 'DD.MM.YYYY HH:mm', 'unix') : formatDate(data.item.deadline_at, 'DD.MM.YYYY HH:mm', 'unix')) }}</div>
                    <div class="text-left" style="padding-left: 7px;line-height: 15px;margin-top: -1px;">-</div>
                    <div>{{formatDate(data.item.deadline_at, 'DD.MM.YYYY HH:mm', 'unix')}}</div>
                </div>
            </template>

            <template #cell(contact)="data">

                    {{ data.item.first_name + ' ' +data.item.last_name }}

            </template>
            <template #cell(description)="data">
                <div style="min-width: 350px">
                    <partial-text :is-html="true" :text="data.item.description"></partial-text>
                </div>
            </template>
            <template #cell(solicitors)="data">
                <template v-if="data.item.solicitors">
                    <span  v-for="solicitor,ind in data.item.solicitors">{{solicitor.name + ((data.item.solicitors.length != (ind + 1)) ? ', ' : '')}}</span>
                </template>
                <span v-else>--</span>
            </template>

        </b-table>


    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import {CONTACT_PREFIX as MODULE_PREFIX, MEETING_PREFIX as PREFIX } from './../../contact/moduleHelper'
    import partialText from '@/views/components/partialText'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,
            partialText,
            vSelect,

        },
        props: ['loggedUser'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                selectedColumns:[],

                columnsDefs: [ //variant: 'danger' whole column
                    {label: 'label_approver', key: 'contact', sortable: false},
                    {label: 'label_creating_date', key: 'created_at', sortable: false, formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }},
                    {label: 'label_meeting_date', key: 'deadline_at', sortable: false, formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }},
                    {label: 'label_meeting_type', key: 'type_trans_index', sortable: false},
                    {label: 'label_description', key: 'description',sortable: false},
                    {label: 'label_consultant', key: 'consultant_name',sortable: false},
                    {label: 'label_solicitors', key: 'solicitors',sortable: false},

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [8, 10, 25, 50, 100],
                },
                filterData: {
                    sort_by_source: 'all',
                    show_all: 0,
                },

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.refreshDataTable();
        },


        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                let url =  '/'+this.MODULE_PREFIX+'_'+this.PREFIX + 's';
                let params = {
                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,
                    show_all: this.filterData.show_all,
                    module_name: 'dashboard'
                };

                this.async('get', url, {
                    params: params
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){
                if(this.$can('view', 'contact.tab_meetings') || this.$can('view', 'contact.tab_meetings'))
                    this.$router.push({name:'contact_tabs', params: {id:item.contact_id, alias:'meeting'}});
            },
            resolveRowClassVariant(item, type) {
                if (!item || type !== 'row') return
                if (item.done_at) return 'row-success';
            },
        },

    }
</script>